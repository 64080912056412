import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";

import { classNames } from "./utils";

const DropdownMenuOption = ({
  title,
  options,
  origin = "origin-top-right",
}) => {
  return (
    <Menu as="div" className="z-50 relative sm:inline-block">
      {({ open }) => (
        <div>
          <div>
            <Menu.Button className="px-3 py-2 mr-2 bg-white bg-opacity-0 mr-1 rounded-md hover:bg-opacity-70 cursor-pointer text-sm text-white inline-flex justify-center focus:outline-none">
              {title}
              <ChevronDownIcon
                className="-mr-1 ml-2 h-5 w-5"
                aria-hidden="true"
              />
            </Menu.Button>
          </div>

          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className={classNames(
                origin === "origin-top-right"
                  ? `${origin} right-0`
                  : `${origin} left-0`,
                options.length > 6 ? "md:w-96" : "",
                "absolute mt-3 p-1 md:p-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
              )}
            >
              <div
                className={classNames(
                  options.length > 6 ? "md:grid-cols-2" : "",
                  "py-1 grid grid-cols-1"
                )}
              >
                {options.map((item) => (
                  <Menu.Item key={item.name}>
                    {({ active }) => (
                      <div
                        className={classNames(
                          active ? "bg-gray-100 text-gray-700" : "",
                          "rounded-md"
                        )}
                      >
                        <a
                          href={item.href}
                          className={classNames(
                            active ? "text-gray-700" : "",
                            "text-left px-4 py-2 text-sm w-full font-semibold inline-block"
                          )}
                        >
                          {item.name}
                        </a>
                      </div>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </div>
      )}
    </Menu>
  );
};

export default DropdownMenuOption;
