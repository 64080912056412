import {Component, Fragment} from "react";
import {Transition} from "@headlessui/react";
import {SearchIcon} from "@heroicons/react/solid";

export default class ClubSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      suggestions: props.clubs,
      show: false
    };
    this.escFunction = this.escFunction.bind(this);
  }

  resetState() {
    this.setState(() => ({
      suggestions: this.props.clubs,
      show: false
    }));
    document.getElementById("club-search-input").value = "";
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      document.activeElement.blur();
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  onFocus = (e) => {
    this.setState(() => ({
      show: true
    }));
  }

  onBlur = (e) => {
    // Ensure that we only hide the matches when focus is lost to something outside our containing div.
    if (!e.relatedTarget || !e.currentTarget.contains(e.relatedTarget)) {
      this.resetState();
    }
  }

  onTextChange = (e) => {
    let suggestions = [];
    const value = e.target.value;

    if (value.length > 0) {
      const regex = new RegExp(`${value}`, `i`);
      suggestions = this.props.clubs.filter(v => regex.test(v.name));
    } else {
      suggestions = this.props.clubs;
    }

    this.setState(() => ({
      suggestions
    }));
  }

  render() {
    return (
      <div className="relative inline-block w-full" onFocus={this.onFocus} onBlur={this.onBlur}>
        <div
          className="inline-flex text-left w-full rounded-md border border-gray-300 shadow-sm px-4 py-0 bg-white text-sm text-gray-400 hover:bg-gray-50 focus:outline-none">
          <input id="club-search-input" onChange={this.onTextChange} placeholder="Search clubs..."
                 className="h-11 bg-transparent focus:outline-none w-full"/>
          <SearchIcon
            className="ml-2 h-5 w-5 text-gray-700 absolute right-6 top-3"
            aria-hidden="true"
          />
        </div>

        <Transition
          show={this.state.show}
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <div id="clubs"
               className="flex content-start h-96 z-40 overflow-scroll overscroll-contain grid md:grid-cols-2 origin-top-right absolute right-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none border border-gray-100 p-4">
            {this.state.suggestions.map((club) => (
              <div key={club.id} className="h-10 rounded-md hover:bg-gray-100 flex items-center">
                <a href={`/clubs/${club.id}`}
                   className="block text-gray-900 hover:text-lsl-green-400 text-left px-4 py-2 text-sm w-full font-semibold inline-block">{club.name}</a>
              </div>
            ))}
          </div>
        </Transition>
      </div>
    );
  }
}
