import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from '@heroicons/react/outline'

import DropdownMenuOption from "./DropdownMenuOption";
import { FbLink, TwitterLink, InstagramLink } from "./SocialLinks";
import { classNames } from "./utils";

const optionsMap = (options) => (
  options.map((option) => ({
    id: option.id,
    name: option.name,
    href: `/divisions/${option.id}`,
  }))
);

const mdNav = (competitionOptions) => [
  { name: "Home", href: "/" },
  { name: "News", href: "/articles" },
  { name: "Clubs", href: "/clubs" },
  {
    name: "Competitions",
    href: "",
    origin: "origin-top-left",
    options: optionsMap(competitionOptions),
  },
];

const mobileNav = [
  { name: "Home", href: "/" },
  { name: "News", href: "/articles" },
  { name: "Clubs", href: "/clubs" },
  { name: "Fixtures", href: "/fixtures" },
  { name: "Results", href: "/results" },
  { name: "Contact", href: "/contact" },
  { name: "Pay a fine", href: "/products" },
  { name: "Insurance", href: "/membership_products" },
];

const LeaguesHeader = ({
  club,
  clubLogo,
  currentPath = "/",
  competitionOptions,
  facebookLink,
  twitterLink,
  instagramLink,
  navOptions,
  mobileNavOptions,
}) => {
  return (
    <Popover
      as="nav"
      className="themed-primary-component border-b-2 border-white border-opacity-10 w-full"
    >
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between h-16">
              <div className="bg-white rounded-full p-2 flex-shrink ml-4 top-3 sm:hidden">
                <a href="/" className="">
                  <img
                    width="40px"
                    src={clubLogo}
                  />
                </a>
              </div>
              <div className="sm:hidden ml-4">
                <div className="flex">
                  <DropdownMenuOption
                    key="competitions"
                    title="Competitions"
                    options={optionsMap(competitionOptions)}
                    origin="origin-top-left"
                  />
                </div>
              </div>
              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <div className="hidden sm:block">
                  <div className="flex space-x-4">
                    {mdNav(competitionOptions).map((item) =>
                      item.options && item.options.length ? (
                        <DropdownMenuOption
                          key={item.name}
                          title={item.name}
                          options={item.options}
                          origin={item.origin}
                        />
                      ) : (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.href === currentPath
                              ? "bg-opacity-20"
                              : "bg-opacity-0",
                            "px-2 sm:px-3 py-2 bg-white rounded-md text-sm text-white font-medium hover:bg-opacity-70 cursor-pointer"
                          )}
                          aria-current={
                            item.href === currentPath ? "page" : undefined
                          }
                        >
                          {item.name}
                        </a>
                      )
                    )}
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 hidden sm:inline-flex sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                <DropdownMenuOption title="More" options={navOptions} />
                {facebookLink && facebookLink.length && (
                  <FbLink link={facebookLink} fill="white" />
                )}
                {twitterLink && twitterLink.length && (
                  <TwitterLink link={twitterLink} fill="white" />
                )}
                {instagramLink && instagramLink.length && (
                  <InstagramLink link={instagramLink} fill="white" />
                )}
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Popover.Button className="inline-flex items-center justify-center p-2 rounded-md text-white hover:text-white lsl:bg-lsl-green-700 nygaa-senior:bg-ny-purple-700 nygaa-minor:bg-ny-red-700 victoriagaa:bg-victoria-blue-700 focus:outline-none">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Popover.Button>
              </div>
            </div>
          </div>
          <Popover.Overlay
            className={`${
              open ? "opacity-50 fixed inset-0" : "opacity-0"
            } bg-black`}
          />

          <Transition
            show={open}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-100 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Popover.Panel className="sm:hidden absolute z-10 left-2 right-2 top-2">
              <div className="px-2 pt-2 pb-3 space-y-1 bg-white rounded-md text-black font-bold">
                <div className="w-full p-4">
                  <a href="/">
                    <img
                      width="40px"
                      className="absolute left-4 top-4"
                      src={clubLogo}
                    />
                  </a>
                  <p className="ml-4 text-center">{club.name}</p>
                  <Popover.Button className="absolute right-4 top-6 text-gray-400 focus:outline-none">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
                <div className="w-full border-b-2 border-gray-200 pb-2">
                  {mobileNavOptions.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className={classNames(
                        item.href === currentPath
                          ? "bg-opacity-100"
                          : "bg-opacity-0 hover:bg-opacity-50",
                        "block text-gray-900 bg-gray-100 px-3 py-2 rounded-md text-base font-medium"
                      )}
                      aria-current={item.current ? "page" : undefined}
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="flex items-left">
                  {facebookLink && facebookLink.length && (
                    <FbLink link={facebookLink} fill="black" />
                  )}
                  {twitterLink && twitterLink.length && (
                    <TwitterLink link={twitterLink} fill="black" />
                  )}
                  {instagramLink && instagramLink.length && (
                    <InstagramLink link={instagramLink} fill="black" />
                  )}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default LeaguesHeader;
