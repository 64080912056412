import ReactOnRails from "react-on-rails";

import CompetitionsDropdown from "Modules/CompetitionsDropdown";
import ClubSearch from "Modules/ClubSearch";
import LeaguesHeader from "Modules/LeaguesHeader";
import MembershipCheckout from "Modules/MembershipCheckout";

import "../css/leagues.css";

ReactOnRails.register({ CompetitionsDropdown, ClubSearch, LeaguesHeader, MembershipCheckout });
