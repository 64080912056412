import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function CompetitionsDropdown({competitions}) {
  return (
    <Menu as="div" className="relative inline-block w-full">
      {({ open }) => (
        <>
          <div>
            <Menu.Button className="inline-flex text-left w-full rounded-md border border-gray-300 shadow-sm px-4 py-3 bg-white text-sm text-gray-400 hover:bg-gray-50 focus:outline-none">
              Find a competition...
              <ChevronDownIcon
                className="ml-2 h-5 w-5 text-gray-700 absolute right-6"
                aria-hidden="true"
              />
            </Menu.Button>
          </div>

          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="flex h-96 overflow-scroll overscroll-contain z-40 grid grid-cols-1 md:grid-cols-2 origin-top-right absolute right-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none border border-gray-100 p-4"
            >
                {competitions.map((competition) => (
                    <Menu.Item key={competition.id}>
                      {({ active }) => (
                        <div
                        className={classNames(
                          active
                            ? "bg-gray-100"
                            : "",
                          "rounded-md"
                        )}>
                          <a
                            href={`/divisions/${competition.id}`}
                            className={classNames(
                              active
                                ? "text-lsl-green-400"
                                : "text-gray-900",
                              "text-left px-4 py-2 text-sm w-full font-semibold inline-block"
                            )}
                          >
                            {competition.name}
                          </a>
                        </div>
                      )}
                    </Menu.Item>
                ))}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
}
